<script lang="ts">
    /**
     * Enables / disables page scrolling.
     * Used to block scrolling of the main content when overlays are opened such as mobile menu or modals.
     */

    import {onDestroy} from "svelte";
    import {mobileMenuOpen} from "@stores/mobile-menu-open";
    import {modal} from "@stores/modal";

    function handlePageScrollBlocking() {
        const preventScrolling = $mobileMenuOpen || $modal !== undefined;
        document.body.style.overflow = preventScrolling ? "hidden" : "auto";
    }

    const unsubscribeMobileMenu = mobileMenuOpen.subscribe(handlePageScrollBlocking);
    const unsubscribeModal = modal.subscribe(handlePageScrollBlocking);

    onDestroy(() => {
        unsubscribeMobileMenu();
        unsubscribeModal();
    });
</script>
